/*
 * Copyright 2020-2021 AskChristee, LLC. All rights reserved.
 */

import { Container } from "react-bootstrap";

function About() {
  return (
    <div>
      <Container className="p-3">
        <h2 className="header mb-5">About</h2>

        <h4>
          Lorem has the knowledge, experience, passion, and technology to
          support your next real estate transaction.
        </h4>

        <p>
          Whether buying your first home, upgrading, downsizing, or investing in
          a vacation home, we have the expertise to support you through every
          step of the process. Lorem Real Estate also is uniquely positioned to
          offer you cutting-edge software tools to assist in the decision-making
          process.
        </p>

        <p>Wondering how much home you can afford?</p>

        <p>
          Trying to decide if you should buy now or wait until you have a larger
          down payment? How would a reverse mortgage work for you? Just "Ask
          Christee." Our Christee software offers various modules that help you
          answer all of your real estate purchasing questions.
        </p>
      </Container>
    </div>
  );
}

export default About;
