/*
 * Copyright 2020-2021 AskChristee, LLC. All rights reserved.
 */

import { Nav, NavDropdown } from "react-bootstrap";

function Buy() {
  return (
    <NavDropdown title="BUY" id="basic-nav-dropdown">
      <Nav.Link href="https://www.tcbrealtor.com/ouragents/">
        Find an Agent
      </Nav.Link>
      <Nav.Link href="https://www.tcbrealtor.com/buy/">For Buyers</Nav.Link>
      <Nav.Link href="https://www.tcbrealtor.com/buy/technology/">
        Technology
      </Nav.Link>
      <Nav.Link href="https://www.tcbrealtor.com/buy/special-buyer-services/">
        Special Buyer Services
      </Nav.Link>
      <Nav.Link href="https://www.tcbrealtor.com/buy/home-buying-steps-with-tcb-realtor/">
        Home Buying Steps with TCB Realtor
      </Nav.Link>
      <Nav.Link href="https://www.tcbrealtor.com/buy/touring-property-guide/">
        Touring Property Guide
      </Nav.Link>
      <Nav.Link href="https://www.tcbrealtor.com/buy/preparing-to-make-an-offer/">
        Preparing to Make an Offer
      </Nav.Link>
      <Nav.Link href="https://www.tcbrealtor.com/buy/buyer-closing-cost/">
        Buyer's Closing Cost
      </Nav.Link>
    </NavDropdown>
  );
}

export default Buy;
