import React from "react";
import { Route, Routes } from "react-router-dom";

import {
  Amortization as AmortizationView,
  Budget as BudgetView,
  BuyerChoice as BuyerChoiceView,
  BuyerQual as BuyerQualView,
  BuyWait as BuyWaitView,
  ClosingCost as ClosingCostView,
  Hecm as HecmView,
  Home as HomeView,
  Investment as InvestmentView,
  Rob as RobView,
  SellerNet as SellerNetView,
  Vacation as VacationView,
  About as AboutView,
  Subsidy as SubsidyView,
  ConsumerDebt as ConsumerDebtView
} from "../src/components/pages";

const routes = () => (
  <Routes>
    <Route path="/" exact element={<HomeView />} />
    <Route path="/amortization" exact element={<AmortizationView />} />
    <Route path="/about" exact element={<AboutView />} />
    <Route path="/buyer-prequal" exact element={<BuyerQualView />} />
    <Route path="/buyer-choice" exact element={<BuyerChoiceView />} />
    <Route path="/buyer-choice" exact element={<BuyerChoiceView />} />
    <Route path="/buy-or-wait" exact element={<BuyWaitView />} />
    <Route path="/buy-or-rent" exact element={<RobView />} />
    <Route path="/budget" exact element={<BudgetView />} />
    <Route path="/closing-cost" exact element={<ClosingCostView />} />
    <Route path="/reverse" exact element={<HecmView />} />
    <Route path="/investment" exact element={<InvestmentView />} />
    <Route path="/seller-net" exact element={<SellerNetView />} />
    <Route path="/vacation" exact element={<VacationView />} />
    <Route path="/consumer-debt" exact element={<ConsumerDebtView />} />
    <Route path="/subsidy" exact element={<SubsidyView />} />
  </Routes>
);
export default routes;
