/**
 * in some cases card data does not have a video link, this is a conditional to show the link
 * if it's not nil
 */
import { Card } from "react-bootstrap";

const VideoLink = (props) => {
  let { link } = props;
  if (link === "nil") {
    return <>&nbsp;</>;
  } else {
    return (
      <Card.Link
        className="carousel-help-card__video"
        href={link}
        target="_new"
      >
        Watch Video ►
      </Card.Link>
    );
  }
};

export default VideoLink;
