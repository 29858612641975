import { ConsumerDebtForm as Form, PoweredBy } from "@ask-christee/ui-response";
import React from "react";
import "../../atoms/Style/form.css";

const ConsumerDebt = () => {
  return (
    <div>
      <main id="maincontent" className="interior">
        <div className="container content spacebelow">
          <div className="row mt-5">
            <div className="col-md-12">
              <div className="contentpadding">
                <div className="form-wrapper">
                  <Form />
                  <div className="container-fluid">
                    <div className="row justify-content-end poweredby">
                      <div className="mt-3 w-auto">
                        <PoweredBy />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default ConsumerDebt;
