/*
 * Copyright 2020-2021 AskChristee, LLC. All rights reserved.
 */

import { Container, Nav, Navbar } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

import About from "./components/about";
import Buy from "./components/buy";
import Finance from "./components/finance";
import Resources from "./components/resources";
import Sell from "./components/sell";
import Search from "./components/search";

function Header() {
  return (
    <Navbar className="headersmall header" bg="light" expand="lg">
      <Container style={{ backgroundColor: "#f8f9fa" }}>
        <div className="col-4 col-lg-2 logo-wrapper">
          <div className="logo">
            {" "}
            <a
              href="https://www.tcbrealtor.com/"
              className="custom-logo-link"
              rel="home"
            >
              <img
                width="160"
                height="62"
                src="https://www.tcbrealtor.com/wp-content/uploads/2020/04/tcb-realtor-logo.png"
                className="custom-logo"
                alt="TCB Realtor Taking Care of Business Real Estate"
              />
            </a>{" "}
          </div>
        </div>

        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" style={{ flexGrow: 0 }}>
          <div className="custom-navbar">
            <div className="header-contact">
              <div>
                <span className="header-contact-phone">
                  Call <a href="tel:443-370-8859">443-370-8859</a>
                </span>{" "}
                <a
                    href="https://www.tcbrealtor.com/contact"
                    className="btn btn-outline"
                >
                  Contact
                </a>
              </div>
            </div>

            <Nav>
              <LinkContainer to="/">
                <Nav.Link>CHRISTEE SOFTWARE</Nav.Link>
              </LinkContainer>
              <Search />
              <About />
              <Sell />
              <Buy />
              <Finance />
              <Resources />
            </Nav>
          </div>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
