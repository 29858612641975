import React from 'react';
import ReactDOM from 'react-dom';
import "./style.css";
import "./home.css";
import "./index.css";
import App from './App';
import reportWebVitals from './reportWebVitals';

import { UiSettingsProvider } from "@ask-christee/ui-response";

ReactDOM.render(
  <React.StrictMode>
    <UiSettingsProvider
      sendPdfLink="https://mkf4bs4ldd.execute-api.us-east-1.amazonaws.com/dev/send-pdf"
      stage={process.env.REACT_APP_ENGINE_STAGE || ""}
      recaptchaKey="6LeEtPEdAAAAAKFnf6tjSDFEW4YmBNoUEtGBwjax"
      companyId={window.location.hostname.split(".")[0]}
    >
      <App />
    </UiSettingsProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
