/*
 * Copyright 2020-2021 AskChristee, LLC. All rights reserved.
 */

import { Nav, NavDropdown } from "react-bootstrap";

function Resources() {
  return (
    <NavDropdown title="RESOURCES" id="basic-nav-dropdown">
      <Nav.Link href="https://www.tcbrealtor.com/resources/">
        Resources
      </Nav.Link>
      <Nav.Link href="https://www.tcbrealtor.com/resources/real-estate-terms/">
        Real Estate Terms
      </Nav.Link>
      <Nav.Link href="https://www.tcbrealtor.com/resources/guide-to-home-inspection/">
        Guide to Home Inspection
      </Nav.Link>
      <Nav.Link href="https://www.tcbrealtor.com/resources/exterior-components/">
        Exterior Components
      </Nav.Link>
      <Nav.Link href="https://www.tcbrealtor.com/resources/diagram-of-a-house/">
        Diagram of a House
      </Nav.Link>
      <Nav.Link href="https://www.tcbrealtor.com/resources/architectural-designs/">
        Architectural Designs
      </Nav.Link>
      <Nav.Link href="https://www.tcbrealtor.com/resources/frequently-asked-questions/">
        Frequently Asked Questions
      </Nav.Link>
      <Nav.Link href="https://www.tcbrealtor.com/resources/careers/">
        Careers
      </Nav.Link>
    </NavDropdown>
  );
}

export default Resources;
