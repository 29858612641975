/* eslint-disable react/jsx-no-target-blank */
/*
 * Copyright 2020-2021 AskChristee, LLC. All rights reserved.
 */
import { Container, Row, Col } from "react-bootstrap";

function Footer() {
  return (
    <div className="footer">
      <Container>
        <Row className="d-flex justify-content-between">
          <Col md={3}>
            <h2>Useful Links</h2>
            <nav>
              <ul id="menu-footer-menu" className="footer-menu">
                <li
                  id="menu-item-349"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-349"
                >
                  <a title="Buy" href="https://www.tcbrealtor.com/buy/">
                    Buy
                  </a>
                </li>
                <li
                  id="menu-item-350"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-350"
                >
                  <a title="Sell" href="https://www.tcbrealtor.com/sell/">
                    Sell
                  </a>
                </li>
                <li
                  id="menu-item-397"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-397"
                >
                  <a
                    title="Real Estate Finance"
                    href="https://www.tcbrealtor.com/real-estate-finance/"
                  >
                    Real Estate Finance
                  </a>
                </li>
                <li
                  id="menu-item-352"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-352"
                >
                  <a
                    title="Resources"
                    href="https://www.tcbrealtor.com/resources/"
                  >
                    Resources
                  </a>
                </li>
                <li
                  id="menu-item-351"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-351"
                >
                  <a
                    title="About Us"
                    href="https://www.tcbrealtor.com/about-us/"
                  >
                    About Us
                  </a>
                </li>
                <li
                  id="menu-item-570"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-570"
                >
                  <a
                    title="Careers"
                    href="https://www.tcbrealtor.com/resources/careers/"
                  >
                    Careers
                  </a>
                </li>
              </ul>
            </nav>
          </Col>

          <Col md={3}>
            <div className="footer-contact">
              <h2>Contact Us Today!</h2>
              <p className="mb-1">
                <a href="tel:443-370-8859">443-370-8859</a>
              </p>
              <p>
                <a href="mailto:info@tcbrealtor.com">info@tcbrealtor.com</a>
              </p>
            </div>
          </Col>
          <Col md={3}>
            <div className="stay-in-touch">
              <h2>
                <span className="gray">Stay in Touch!</span>
              </h2>
              <section className="social" aria-label="Social Network Links">
                <span className="custom-social align-middle">
                  <a
                    href="https://www.facebook.com/TCBRealtorMD"
                    title=""
                    aria-label="Facebook"
                    target="_blank"
                    rel="noopener"
                  >
                    <span aria-hidden="true" className="fa fa-facebook"></span>
                  </a>
                </span>
                <span className="custom-social align-middle">
                  <a
                    href="https://www.instagram.com/tcbrealtor/"
                    title=""
                    aria-label="instagram"
                    target="_blank"
                    rel="noopener"
                  >
                    <span aria-hidden="true" className="fa fa-instagram"></span>{" "}
                  </a>
                </span>{" "}
                <span className="custom-social align-middle">
                  <a
                    href="https://twitter.com/TCBrealtorMD"
                    title=""
                    aria-label="Twitter"
                    target="_blank"
                    rel="noopener"
                  >
                    <span aria-hidden="true" className="fa fa-twitter"></span>{" "}
                  </a>
                </span>{" "}
                <span className="custom-social align-middle">
                  <a
                    href="https://www.linkedin.com/company/tcb-realtor"
                    title=""
                    aria-label="Linkedin"
                    target="_blank"
                    rel="noopener"
                  >
                    <span aria-hidden="true" className="fa fa-linkedin"></span>
                  </a>
                </span>
              </section>
            </div>
          </Col>
        </Row>

        <Row>
          <div className="siteinfo">
            <p className="mb-1">&copy; 2022 TCB Realtor. All rights reserved.</p>
            <p>
              Terms of Use /{" "}
              <a href="https://www.tcbrealtor.com/privacy-policy/">
                Privacy Policy
              </a>
            </p>
          </div>
        </Row>
      </Container>
    </div>
  );
}
export default Footer;
