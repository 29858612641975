import React from "react";
import { Link } from "react-router-dom";
import IconOverlay from "../IconOverlay";
import closingCost from "./closingCost.svg";
import sellerNet from "./sellerNet.svg";
import vacation from "./vacation.svg";
import buyRent from "../LegacySvg/buyRent.svg";
import buyerPrequal from "../LegacySvg/buyerPrequal.svg";
import buyerChoice from "../LegacySvg/buyerChoice.svg";
import buyWait from "../LegacySvg/buyWait.svg";
import reverseManager from "../LegacySvg/reverseManager.svg";
import amortization from "../LegacySvg/amortization.svg";
import budget from "../LegacySvg/budget.svg";
import investmentAnalysis from "../LegacySvg/investmentAnalysis.svg";

const IconGrid = () => {
  console.log(closingCost);
  console.log(sellerNet);
  console.log(vacation);

  return (
    <>
      <div className="module-grid home-spacing bg-graygradient">
        <div className="container">
          <h2 className="topHeading text-left  w-100 mb-2">
            Select a module below to get started
          </h2>
          <div className="row justify-content-center lg-gutters mt-5">
            <div className="col-12 col-md-3">
              <div className="module-item">
                <IconOverlay title="Buyer Prequal">
                  <p className="text-left">
                    What price home is best suited to my budget? What is the
                    best mortgage option for me? What will my closing cost be?
                    How much can the seller contribute to my closing cost? How
                    should I utilize my cash? What tax deduction will a new home
                    purchase create? How is the tax deduction beneficial to me?
                  </p>
                </IconOverlay>
                <Link to="/buyer-prequal">
                  <img
                    src={buyerPrequal}
                    alt="Buyer Prequal"
                    style={{ display: "block", margin: "auto" }}
                  />
                  <p className="text-center">Buyer PreQual</p>
                </Link>
              </div>
            </div>
            <div className="col-12 col-md-3">
              <div className="module-item">
                <IconOverlay title="Buyer Choice">
                  <p className="text-left">
                    You have found a home you would like to purchase. What is
                    the best financing option? What is the minimum amount of
                    cash necessary to buy the home? Should you invest all of
                    your cash? What is the best financing option? How much are
                    the closing cost? How will the purchase effect my tax
                    liability?
                  </p>
                </IconOverlay>
                <Link to="/buyer-choice">
                  <img
                    src={buyerChoice}
                    alt="Buyer Choice"
                    style={{ display: "block", margin: "auto" }}
                  />
                  <p className="text-center">Buyer Choice</p>
                </Link>
              </div>
            </div>
            <div className="col-12 col-md-3">
              <div className="module-item">
                <IconOverlay title="Closing Cost">
                  <p className="text-left">
                    What will the closing costs be for this house I'm interested
                    in?
                  </p>
                </IconOverlay>
                <Link to="/closing-cost">
                  <img
                    src={closingCost}
                    alt="Closing Cost"
                    style={{ display: "block", margin: "auto" }}
                  />
                  <p className="text-center">Closing Cost</p>
                </Link>
              </div>
            </div>
            <div className="col-12 col-md-3">
              <div className="module-item">
                <IconOverlay title="Buy or Rent" placement="left">
                  <p className="text-left">
                    Is now the right time for me to purchase a home or should I
                    rent? How much cash will be required to purchase a home?
                    Over a period of time what will be rent compared to mortgage
                    payment? What will be the projected net equity of home
                    ownership? How will ownership affect my tax liability?
                  </p>
                </IconOverlay>
                <Link to="/buy-or-rent">
                  <img
                    src={buyRent}
                    alt="Buy Rent"
                    style={{ display: "block", margin: "auto" }}
                  />
                  <p className="text-center">Buy or Rent</p>
                </Link>
              </div>
            </div>
            <div className="col-12 col-md-3">
              <div className="module-item">
                <IconOverlay title="Buy or Wait">
                  <p className="text-left">
                    This program provides powerful analytics for assisting a
                    prospective home buyer in making the decision to buy 'Today'
                    or 'Wait'. Is now the right time to purchase a home or
                    should I wait? How much cash will be required to purchase a
                    home? What will be the projected net equity of home
                    ownership? How will ownership affect my tax liability?
                  </p>
                </IconOverlay>
                <Link to="/buy-or-wait">
                  <img
                    src={buyWait}
                    alt="Buy Or Wait"
                    style={{ display: "block", margin: "auto" }}
                  />
                  <p className="text-center">Buy or Wait</p>
                </Link>
              </div>
            </div>
            <div className="col-12 col-md-3">
              <div className="module-item">
                <IconOverlay title="Reverse Mortgage">
                  <p className="text-left">
                    Also known as the Home Equity Conversion Mortgage. For many
                    seniors, 62 years or older, a reverse mortgage is a viable
                    option. This program provides the concise data necessary to
                    allow the senior to make an intelligent decision knowing all
                    the available options.
                  </p>
                </IconOverlay>
                <Link to="/reverse">
                  <img
                    src={reverseManager}
                    alt="Reverse Mortgage"
                    style={{ display: "block", margin: "auto" }}
                  />
                  <p className="text-center">Reverse Mortgage</p>
                </Link>
              </div>
            </div>
            <div className="col-12 col-md-3">
              <div className="module-item">
                <IconOverlay title="Amortization">
                  <p className="text-left">
                    The program performs the basic amortization of any loan
                    based upon interest rate and term of loan. Additionally, you
                    may enter additional principal payments or enter a term
                    which you would like the loan to be fully amortized. The
                    results detail total interest paid over the mortgage term,
                    annual allocation of payments as a percentage to principal
                    and interest.
                  </p>
                </IconOverlay>
                <Link to="/amortization">
                  <img
                    src={amortization}
                    alt="Amortization"
                    style={{ display: "block", margin: "auto" }}
                  />
                  <p className="text-center">Amortization</p>
                </Link>
              </div>
            </div>
            <div className="col-12 col-md-3">
              <div className="module-item">
                <IconOverlay title="Budget" placement="left">
                  <p className="text-left">
                    What is an affordable monthly mortgage payment? The amount a
                    Lender establishes? Maybe. Realistically, an affordable
                    mortgage payment is one that fits within your monthly
                    budget. Monthly Budget allows an overview of current monthly
                    obligations including Federal and State Income Taxes and the
                    effects and of the proposed mortgage payment.
                  </p>
                </IconOverlay>
                <Link to="/budget">
                  <img
                    src={budget}
                    alt="Budget"
                    style={{ display: "block", margin: "auto" }}
                  />
                  <p className="text-center">Budget</p>
                </Link>
              </div>
            </div>
            <div className="col-12 col-md-3">
              <div className="module-item">
                <IconOverlay title="Vacation">
                  <p className="text-left">
                    The Vacation module has 2 distinct functionalities depending
                    upon user input. You may enter a 'sales price' of a
                    potential property and Christee will analyze the best
                    options for you to purchase that property. If you do not
                    enter a 'sales price' then Christee will develop a
                    'projected purchase price' based upon your inputs.
                  </p>
                </IconOverlay>

                <Link to="/vacation">
                  <img
                    src={vacation}
                    alt="Vacation"
                    style={{ display: "block", margin: "auto" }}
                  />
                  <p className="text-center">Vacation</p>
                </Link>
              </div>
            </div>
            <div className="col-12 col-md-3">
              <div className="module-item">
                <IconOverlay title="Seller Net" placement="left">
                  <p className="text-left">
                    The seller net module calculates the estimated net proceeds
                    to the seller. The module deducts normal expenses from the
                    gross sales price to arrive at the ‘net’ dollar proceeds.
                  </p>
                </IconOverlay>
                <Link to="/seller-net">
                  <img
                    src={sellerNet}
                    alt="Seller Net"
                    style={{ display: "block", margin: "auto" }}
                  />
                  <p className="text-center">Seller Net</p>
                </Link>
              </div>
            </div>
            <div className="col-12 col-md-3">
              <div className="module-item">
                <IconOverlay title="Investment Analysis" placement="left">
                  <p className="text-left">
                    The potential purchase of income producing or rental
                    property is evaluated differently depending upon the desired
                    result. Christee provides all the analysis to allow for an
                    informed decision.
                  </p>
                </IconOverlay>
                <Link to="/investment">
                  <img
                    src={investmentAnalysis}
                    alt="Investment"
                    style={{ display: "block", margin: "auto" }}
                  />
                  <p className="text-center">Investment Analysis</p>
                </Link>
              </div>
            </div>
            <div className="col-12 col-md-3">
              <div className="module-item">
                <IconOverlay title="Consumer Debt">
                  <p className="text-left">
                    What are the options for adding credit card debt into the
                    mortgage?
                  </p>
                </IconOverlay>
                <Link to="/consumer-debt">
                  <img
                    src={buyerChoice}
                    alt="Consumer Debt"
                    style={{ display: "block", margin: "auto" }}
                  />
                  <p className="text-center">Consumer Debt</p>
                </Link>
              </div>
            </div>
            <div className="col-12 col-md-3">
              <div className="module-item">
                <IconOverlay title="Payment Subsidy">
                  <p className="text-left">
                    What are the options for payment subsidy?
                  </p>
                </IconOverlay>
                <Link to="/subsidy">
                  <img
                    src={closingCost}
                    alt="Payment Subsidy"
                    style={{ display: "block", margin: "auto" }}
                  />
                  <p className="text-center">Payment Subsidy</p>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default IconGrid;
