import cardHouse from "./cardHouse.svg";

export const data = [
  {
    image: cardHouse,
    title: "Getting Started",
    description:
      "The first step in buying a home should be to develop a financial game plan to include an affordable monthly payment, home price, loan type and required cash including down payment and closing cost which may be reduced with either seller or lender closing help.",
    link: "/buyer-prequal",
    linkTitle: "Buyer PreQual Module",
    video: "https://resources.askchristee.com/video/buyer-prequal-basics",
  },
  {
    image: cardHouse,
    title: "Purchasing a Specific Property",
    description:
      "Once you located a potential home, you need to evaluate the best financial options available. You can view multiple options for loan type, monthly payment, down payment, closing cost, closing hep and required cash. Within seconds you can develop multiple purchasing scenarios to review with your lender.",
    link: "/buyer-choice",
    linkTitle: "Buyer Choice Module",
    video: "https://resources.askchristee.com/video/buyer-choice-basics/",
  },
  {
    image: cardHouse,
    title: "Closing costs",
    description:
      "Closing costs are major factor when buying real estate. Each Christee module will provide an in-depth breakdown of closing cost based upon location, home price, loan type, seller or lender concessions and close date.  The closing cost module will provide closing cost-plus mortgage payment details.",
    link: "/closing-cost",
    linkTitle: "Closing Cost Module",
    video:
      "https://resources.askchristee.com/video/understanding-closing-cost/",
  },
  {
    image: cardHouse,
    title: "Buy or Rent Analysis",
    description:
      "Buying a home is both an emotional and financial decision. The decision should be made based upon objective facts such as monthly rent vs mortgage payment, required cash and return on cash investment, potential tax savings, loan type and potential property equity. Complete non-emotional report.",
    link: "/buy-or-rent",
    linkTitle: "Buy Rent Module",
    video: "https://resources.askchristee.com/video/buy-or-rent/",
  },
  {
    image: cardHouse,
    title: "Buy Today or Wait",
    description:
      "When making this difficult decision, you should evaluate all potential influencers such as interest rates, future property values, potential equity, tax saving, loan type and required cash including down payment and closing cost.",
    link: "/buy-or-wait",
    linkTitle: "Buy or Wait Module",
    video: "https://resources.askchristee.com/video/buy-or-wait/",
  },
  {
    image: cardHouse,
    title: "Reverse Mortgage Option",
    description:
      "If you are 62 years or older, purchasing a home a reverse mortgage may be a viable option. Since there in not a required mortgage payment, your options are based upon age and available cash for down payment and closing cost. Only available here.",
    link: "/reverse",
    linkTitle: "Reverse Mortgage Module",
    video: "https://resources.askchristee.com/video/reverse-mortgage-part-1/",
  },
  {
    image: cardHouse,
    title: "Buying a 2nd Home",
    description:
      "or vacation home is uniquely different than purchasing a primary home or an investment property from a mortgage loan prospective and potential tax deductions. Additionally, be alerted to those areas with 2nd home property tax increases.",
    link: "/vacation",
    linkTitle: "Vacation Home Module",
    video: "https://resources.askchristee.com/video/vacation-part-1/",
  },
  {
    image: cardHouse,
    title: "Investing in Real Estate",
    description:
      "is both an art and a science. Developing a complete investment analysis can be time consuming and expensive. Within seconds you can evaluate the investment aspects of any property including cash flow and depreciation schedules.",
    link: "/investment",
    linkTitle: "Investment Module",
    video: "https://resources.askchristee.com/video/investment-analysis/",
  },
  {
    image: cardHouse,
    title: "Budget",
    description:
      "What is an affordable monthly mortgage payment that fits within your lifestyle? Monthly Budget allows an overview of current monthly obligaBons including Federal and State Income Taxes and how a new mortgage payment fits in your budget.",
    link: "/budget",
    linkTitle: "Budget Module",
    video: "nil",
  },
  {
    image: cardHouse,
    title: "Seller Net",
    description:
      "The seller net module calculates the estimated net proceeds to the seller. The module deducts normal expenses from the gross sales price to arrive at the 'net' dollar proceeds based upon the location of the property and closing date.",
    link: "/seller-net",
    linkTitle: "Seller Net Module",
    video: "nil",
  },
  {
    image: cardHouse,
    title: "Home Wealth Analysis",
    description:
      "For the vast majority of people, their most valuable asset is their home. This module explores possible wealth as a result of property appreciation and liquidation of mortgage debt. Potential equity wealth is displayed on a long term and annual basis.",
    link: "/equity",
    linkTitle: "Home Wealth Analysis Module",
    video: "nil",
  },
  {
    image: cardHouse,
    title: "Amortization",
    description:
      "Amortization is the process of liquidation or paying down a loan balance - also referred to as the principal balance. The report will show the amount paid each year to interest and principal as a dollar amount and percent of payments. A very easy tool to track your loan progress.",
    link: "/amortization",
    linkTitle: "Amortization Module",
    video: "nil",
  },
  {
    image: cardHouse,
    title: "Payment Subsidy",
    description:
      "A Payment Subsidy is also known as a 2-1 Payment Buydown. The first-year payment will be based upon an interest rate 2% below the fix rate. The second-year payment will be based upon a rate 1% below the mortgage rate. A great option to explore in times of increasing mortgage rates.",
    link: "/subsidy",
    linkTitle: "Payment Subsidy Module",
    video: "nil",
  },
  {
    image: cardHouse,
    title: "Consumer Debt",
    description:
      "This module will allow you to see the time required to pay off a consumer debt such as a credit card and the total (not tax deductible) interest to be paid. Additonally, this module will allow you to compare the credit card debt to the same amount included in a mortgage loan.",
    link: "/consumer-debt",
    linkTitle: "Consumer Debt Module",
    video: "nil",
  },
  {
    image: cardHouse,
    title: "AskChristee Modules",
    description:
      "Christee offers 14 distinct modules to help guide you thru the complexites of a real transaction. Each module is designed to give accurate and critical information to allow for smarter real estate purchasing. We hope you enjoy your Christee experience!",
    link: "/",
    linkTitle: "AskChristee Modules",
    video: "nil",
  },
];
