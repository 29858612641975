/*
 * Copyright 2020-2021 AskChristee, LLC. All rights reserved.
 */

import { Nav, NavDropdown } from "react-bootstrap";

function About() {
  return (
    <NavDropdown title="ABOUT US" id="basic-nav-dropdown">
      <Nav.Link href="https://www.tcbrealtor.com/about-us/">About Us</Nav.Link>
      <Nav.Link href="https://www.tcbrealtor.com/ouragents/">
        Our Agents
      </Nav.Link>
      <Nav.Link href="https://www.tcbrealtor.com/about-us/our-listings/">
        Our Listings
      </Nav.Link>
    </NavDropdown>
  );
}

export default About;
