import React, {useContext, useEffect} from "react";

import { BrowserRouter } from "react-router-dom";
// @ts-ignore
import {UiSettingsProviderContext} from "@ask-christee/ui-response";

import "./App.scss";
import Routes from "./routes";
import Footer from "./components/atoms/TCBFooter";
import Header from "./components/atoms/TCBHeader";


function App() {
  const { currentSettings, updateCurrentSettings } = useContext(
    UiSettingsProviderContext
  );

  useEffect(() => {
    updateCurrentSettings({
      ...currentSettings,
      accountType: "agent",
      isApproved: true,
      initialCompletion: "2548530000000"
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <BrowserRouter>
      <Header />
      <Routes />
      <Footer />
    </BrowserRouter>
  );
}

export default App;
