/*
 * Copyright 2020-2021 AskChristee, LLC. All rights reserved.
 */

import { Nav, NavDropdown } from "react-bootstrap";

import * as Icon from 'react-bootstrap-icons';

function Search() {
  return (
    <NavDropdown title="SEARCH" id="basic-nav-dropdown">
      <Nav.Link href="https://tcbrealtor.idxbroker.com/idx/search/advanced"><Icon.GearFill /> Advanced Search</Nav.Link>
      <Nav.Link href="https://tcbrealtor.idxbroker.com/idx/search/address">
        <Icon.GeoFill /> Address Search
      </Nav.Link>
      <Nav.Link href="https://tcbrealtor.idxbroker.com/idx/map/mapsearch">
        <Icon.CardImage /> Map Search
      </Nav.Link>
      <Nav.Link href="https://tcbrealtor.idxbroker.com/idx/search/basic">
        <Icon.LightbulbFill /> Easy Search
      </Nav.Link>
      <Nav.Link href="https://tcbrealtor.idxbroker.com/idx/search/listingid">
        <Icon.Search /> Listing ID Search
      </Nav.Link>
    </NavDropdown>
  );
}

export default Search;
