/*
 * Copyright 2020-2021 AskChristee, LLC. All rights reserved.
 */

import { Nav, NavDropdown } from "react-bootstrap";

function Finance() {
  return (
    <NavDropdown title="REAL ESTATE FINANCE" id="basic-nav-dropdown">
      <Nav.Link href="https://www.tcbrealtor.com/real-estate-finance/">
        Real Estate Finance
      </Nav.Link>
      <Nav.Link href="https://www.tcbrealtor.com/real-estate-finance/financing-terms/">
        Financing Terms
      </Nav.Link>
      <Nav.Link href="https://www.tcbrealtor.com/real-estate-finance/formal-loan-application-process/">
        Formal Loan Application Process
      </Nav.Link>
      <Nav.Link href="https://www.tcbrealtor.com/real-estate-finance/buyers-closing-cost/">
        Buyers' Closing Cost
      </Nav.Link>
      <Nav.Link href="https://www.tcbrealtor.com/real-estate-finance/sellers-closing-cost/">
        Sellers' Closing Cost
      </Nav.Link>
    </NavDropdown>
  );
}

export default Finance;
