import React from "react";

import {
  IconGrid,
} from '../../atoms/';

import HomeCarousel from "../../carousel";
import { data } from "../../carousel/dat";

function Home() {
  let version = process.env.REACT_APP_VERSION;
  console.log('christee version: ', version);

  return (
    <div>
      <main id="maincontent" className="interior">
        <div className="container content ">
          <div className="row">
            <div className="col-md-12">
              <div className="contentpadding">
                <div className="form-wrapper">
                  <IconGrid />
                  <br/>
                  <br/>
                  <h2>Need Help Getting Started?</h2>
                  <HomeCarousel data={data} />
                  <div className="container-fluid">
                    <div className="row justify-content-end poweredby">
                      <div className="col-md-12"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Home;
