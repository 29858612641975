/*
 * Copyright 2020-2021 AskChristee, LLC. All rights reserved.
 */

import { Nav, NavDropdown } from "react-bootstrap";

function Sell() {
  return (
    <NavDropdown title="SELL" id="basic-nav-dropdown">
      <Nav.Link href="https://www.tcbrealtor.com/sell/">Sell</Nav.Link>
      <Nav.Link href="https://www.tcbrealtor.com/sell/">For Sellers</Nav.Link>
      <Nav.Link href="https://www.tcbrealtor.com/sell/not-happy-dont-pay/">
        Not Happy, Don't Pay
      </Nav.Link>
      <Nav.Link href="https://www.tcbrealtor.com/sell/why-list-with-tcb-realtor">
        Why List with TCB Realtor?
      </Nav.Link>
      <Nav.Link href="https://www.tcbrealtor.com/sell/tcb-realtor-unique-services-for-sellers">
        TCB Realtor Unique Services for Sellers
      </Nav.Link>
      <Nav.Link href="https://www.tcbrealtor.com/sell/protecting-the-interest-of-the-seller/">
        Protecting the Interest of the Seller
      </Nav.Link>
      <Nav.Link href="https://www.tcbrealtor.com/sell/what-is-staging/">
        What is Staging?
      </Nav.Link>
      <Nav.Link href="https://www.tcbrealtor.com/sell/sellers-closing-cost/">
        Seller's Closing Cost
      </Nav.Link>
      <Nav.Link href="https://www.tcbrealtor.com/sell/seller-closing-process/">
        Seller Closing Process
      </Nav.Link>
    </NavDropdown>
  );
}

export default Sell;
