import React, { useState, useRef } from 'react';
import { Button, Overlay, Popover } from 'react-bootstrap';
import { InfoCircle } from 'react-bootstrap-icons';


const IconOverlay = ({children, title, placement }) => {
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const ref = useRef(null);

  const handleClick = (event) => {
    setShow(!show);
    setTarget(event.target);
  };

  return (
    <div ref={ref} className="float-end" style={{position: 'absolute', right: 0, top: 0}}>

      <Button onClick={handleClick} variant="link" ><InfoCircle /></Button>

      <Overlay
        show={show}
        target={target}
        placement={placement || 'right'}
        container={ref}
        containerPadding={20}
      >
        <Popover id="popover-contained" style={{minWidth: 600, maxWidth: 600, textAlign: 'left'}}>
          { title ? <Popover.Header as="h3">{title}</Popover.Header> : <></> }
          <Popover.Body>
            <div>{children || `Add Content to this overlay between the tags!!`}</div>
          </Popover.Body>
        </Popover>
      </Overlay>
    </div>
  );
}

export default IconOverlay;
